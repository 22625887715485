import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import { colors } from '../consts/style';

export const Wrapper = styled.footer`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  width: 100%;
  color: ${colors.gray1};
  padding: 96px 32px 32px;
  font-size: 14px;
  position: relative;

  &:before {
    content: '';
    border-top: 1px solid ${colors.gray3};
    width: 100%;
    max-width: 1040px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  a {
    color: ${colors.gray1};
    font-size: 14px;

    &:hover {
      text-decoration: none;
      color: ${colors.black2};
    }
  }
`;

export const Copyright = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  max-width: 1040px;
  margin: 0 0 32px;
  color: ${colors.gray1};

  p {
    text-align: center;
    color: ${colors.gray1};
    margin: 0;
    width: 70%;
    letter-spacing: 0;
  }
`;

export const FooterNav = styled.nav`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 80px;
  margin: 0 0 20px;

  @media (max-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;

    & > div {
      margin: 0 0 16px;
    }
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }

  strong {
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.1;
    color: ${colors.gray1};
    letter-spacing: 0.5px;
    margin: 0 0 12px;
  }

  ul {
    list-style: none;
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0 0 12px;
    line-height: 1;
  }

  a {
    font-size: 14px;
    line-height: 1.1;
    color: ${colors.black3};
    letter-spacing: 0;

    &:hover {
      color: ${colors.black2};
    }
  }
`;

const Footer = () => {
  const data = useStaticQuery(footerQuery);
  const footerColumn = data.menu.footerColumn;
  const footerCopy = data.copy.footerCopyrightsNode.childMarkdownRemark.html;

  return (
    <Wrapper>
      <FooterNav>
        {footerColumn.map(column => (
          <div key={column.id}>
            <strong>{column.columnTitle}</strong>
            <ul>
              {column.columnItem.map(item => (
                <li key={item.id}>
                  <a href={item.href}>{item.title}</a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </FooterNav>
      <Copyright
        dangerouslySetInnerHTML={{
          __html: footerCopy,
        }}
      />
    </Wrapper>
  );
};

export default Footer;

const footerQuery = graphql`
  {
    menu: datoCmsFooterNavigation {
      footerColumn {
        columnTitle
        columnItem {
          title
          href
          id
        }
        id
      }
    }
    copy: datoCmsSiteConfig {
      footerCopyrightsNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
