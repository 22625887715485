/* eslint-disable quotes */
import bp from './breakpoints';

const mQueryPoint = {
  mobile: `${bp.mobile}px`,
  tabletSmall: `${bp.tabletSmall}px`,
  tablet: `${bp.tablet}px`,
  desktop: `${bp.desktop}px`,
};

export const bz = `
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  `;

export const font = {
  sansSerif: `
        'Graphik Web', Arial, Helvetica, 'Nimbus Sans', sans-serif;
    `,
  h1: `
        font-size: 48px;
        line-height: 1.125;
        @media screen and (max-width: ${mQueryPoint.tablet}) {
            font-size: 32px;
        }
    `,
  h2: `
        font-size: 32px;
        line-height: 1.68;
        @media screen and (max-width: ${mQueryPoint.tablet}) {
            font-size: 28px;
        }
    `,
  h3: `
        font-size: 24px;
        line-height: 1.5;
    `,
  h4: `
        font-size: 20px;
        line-height: 1.4;
    `,
  h5: `
        font-size: 18px;
        line-height: 1;
    `,
  button: `
        font-weight: 700;
    `,
};

export const colors = {
  dark: '#191b1f',
  light: '#fafafa',
  black: '#000',
  black2: '#1f1f1f',
  black3: '#262626',
  white: '#fff',
  gray1: '#666',
  gray3: '#ddd',
  gray2: '#a8a8a8',
  gray4: '#6e6e6e',
  gray5: '#929292',
  gray6: '#bbb',
  gray7: '#e4e4e4',
  gray8: '#f2f2f2',
  gray9: '#f7f7f7',
  ramotionBlue: '#006FF9',
  ramotionBlueHover: '#0059C7',
  lighBlue: '#F2F8FF',
};

export const z = {
  modalBackground: `z-index: 100000;`,
  modal: `z-index: 200000;`,
};

import { css } from 'styled-components';

// Creates up & down media queries for your breakpoints
// *** Usage ***
// import { mq } from "**/style.js"
// export const StyledComponent = styled.div`
// ${media.tablet`
//   display: flex;
// `}
// ${media.mobile_up`
//   display: none;
// `}
//`

export const mq = Object.keys(bp).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media screen and (max-width: ${bp[label]}px) {
      ${css(...args)};
    }
  `;

  acc[`${label}_up`] = (...args) => css`
    @media screen and (min-width: ${bp[label]}px) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});
