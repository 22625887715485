import { createGlobalStyle } from 'styled-components';
import '../fonts/fonts.css';
import '@fontsource/ibm-plex-mono';

const Global = createGlobalStyle`

html, body {
  font-family: 'Graphik Web', Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  font-style: normal;
}

h1, h2, h3, h4, h5, figcaption {
  font-family: 'Graphik Web', Arial, Helvetica, sans-serif;
  font-weight: 600;
}

h1, h2, h3 {
  font-weight: 700;
}

ol {
  list-style-type: none;
  counter-reset: item;
  padding: 0;
  margin: 0;

  li {
    position: relative;
    counter-increment: item;
    margin: 0 0 8px;
    font-size: 18px;
    line-height: 1.8;

    &::before {
      position: relative;
      display: inline-block;
      content: counter(item) '.';
      color: #141414;
      height: 34px;
      min-width: 24px;
      text-align: center;
      border-radius: 50%;
      margin-right: 8px;
      font-size: 18px;
      font-weight: 600;
      line-height: 34px;
    }
  }
}

@supports (font-variation-settings: normal) {
  html, body {
    font-family: 'Graphik Web', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 1.8;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.04em;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mobile-nav-visible {
  overflow: hidden;
}

`;

export default Global;
