import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../consts/style';

const Btn = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  user-select: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  display: none;
  position: relative;
  z-index: 3;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 24px;
    height: 2px;
    background: ${colors.black2};
    transition: transform 0.2s;
    margin-left: -12px;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &:before {
    transform: ${props =>
      props.isOpen ? 'rotate(45deg)' : 'translateY(-7px)'};
  }

  &:after {
    transform: ${props =>
      props.isOpen ? 'rotate(-45deg)' : 'translateY(7px)'};
  }

  span {
    display: block;
    width: 24px;
    height: 2px;
    background: ${colors.black2};
    position: relative;
    transition: opacity 0.2s;
    opacity: ${props => (props.isOpen ? 0 : 1)};
    margin-left: -12px;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  @media (max-width: 1200px) {
    display: block;
  }

  &:active,
  &:focus {
    outline: none;
  }
`;

export default function BurgerMenu({ isOpen, toggle }) {
  return (
    <Btn isOpen={isOpen} onClick={toggle}>
      <span></span>
    </Btn>
  );
}
