import React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

const CtaWrapper = styled.div`
    z-index: 10;

    a {
      color: #262626;
      border: 1px solid #262626;
      border-radius: 20px;
      padding: 0;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.4px;
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
  
      &:hover {
        text-decoration: none !important;
        
        .hover-text {
          opacity: 1;
          visibility: visible;
          z-index: 1;
        }
  
        .button-overlay {
          transform: translateX(0);
          visibility: visible;
        }

        .arrow {
          svg {
            transform: translateX(0);
          }
        }
      }

      &.slide-out {
          .button-overlay {
            transform: translateX(100%);
            visibility: visible;
          }
          .hover-text {
            opacity: 0;
          }
        }
  
      & > span {
        color: #262626;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        padding: 6px 24px;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.4px;
        width: 100%;
        transition: transform 0.3s ease, opacity 0.3s ease;

        &.default-text {
          color: #262626;
        }
  
        &.hover-text {
          color: #fafafa;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          visibility: hidden;
          opacity: 0;
        }
  
        &.button-overlay {
          background: #262626;
          color: #fafafa;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          transform: translateX(-100%);
          visibility: hidden;
        }
      }

      .arrow {
        display: inline-block;
        overflow: hidden;
        margin-left: 4px;
        line-height: 1;
        position: relative;
        top: 0;

        @media (max-width: @min-width-laptop-l) {
          top: 1px;
        }

        svg {
          transform: translateX(-4px);
          transition: transform 0.3s ease-out;
        }
      }
  }

  .nav-overlay {
    opacity: 0;
    display: block;
    height: calc(100vh + 100px);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background: #262626;
    visibility: hidden;
    transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
  
    .subnav-open & {
      visibility: visible;
      opacity: 0.2;
    }
  }
`;

export default function ContactCta() {
  useEffect(() => {
    const button = document.querySelector('.contact-us');
    if (!button) return;

    let isSliding = false;
    let slideOutTimeoutId = null;

    function debounce(func, wait) {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    }

    const removeSlideOut = debounce(() => {
      if (!button.matches(':hover')) {
        requestAnimationFrame(() => {
          button.classList.remove('slide-out');
          isSliding = false;
        });
      }
    }, 50); // Adjust this value as needed

    button.addEventListener('mouseenter', () => {
      clearTimeout(slideOutTimeoutId);
      if (isSliding) {
        button.classList.remove('slide-out');
      }
      isSliding = false;
    });

    button.addEventListener('mouseleave', () => {
      if (!isSliding) {
        isSliding = true;
        requestAnimationFrame(() => {
          button.classList.add('slide-out');
        });
      }
      slideOutTimeoutId = setTimeout(removeSlideOut, 300); // Adjust this to match your transition duration
    });

    button.addEventListener('transitionend', (event) => {
      if (event.propertyName === 'transform') { // Adjust this if you're animating a different property
        removeSlideOut();
      }
    });

    // Cleanup event listeners on component unmount
    return () => {
      button.removeEventListener('mouseenter', () => {});
      button.removeEventListener('mouseleave', () => {});
      button.removeEventListener('transitionend', () => {});
    };
  }, []); // Empty dependency array ensures this runs once after initial render

  return (
    <CtaWrapper>
      <a href="https://www.ramotion.com/contact/" title="Contact" className="contact-us">
        <span className="default-text">
          Contact
          <span className="arrow">
            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.1237 4.81222L9.45487 1.14342L10.5155 0.0827573L15.9956 5.56283L10.5155 11.0429L9.45487 9.98225L13.1249 6.31222L0.187622 6.31226L0.187622 4.81226L13.1237 4.81222Z" fill="#262626"/>
            </svg>              
          </span>
        </span>
        <span className="hover-text">
          Contact
          <span className="arrow">
            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.1237 4.81222L9.45487 1.14342L10.5155 0.0827573L15.9956 5.56283L10.5155 11.0429L9.45487 9.98225L13.1249 6.31222L0.187622 6.31226L0.187622 4.81226L13.1237 4.81222Z" fill="#fff"/>
            </svg>              
          </span>
        </span>    
        <span className="button-overlay"></span>
      </a>
    </CtaWrapper>
  );
}
