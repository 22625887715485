import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors } from '../consts/style';

const SiteNavWrapper = styled.nav`
  display: flex;
  align-items: center;
  color: ${colors.dark};
  font-size: 1.3rem;
  z-index: 1000;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const SiteNavLeft = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  margin-right: 10px;
  white-space: nowrap;
  z-index: 1000;

  ::-webkit-scrollbar {
    display: none;
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      color: ${colors.black3};
      cursor: pointer;
      margin: 0;
      font-size: 16px;
      line-height: 1.5;
      display: flex;
      align-items: center;
      padding: 12px 16px;

      &:hover {
        color: ${colors.black3};
      }

      &:hover {
        .subnav, .cols {
          top: 100%;
        }
      }

      a,
      span {
        position: relative;
        display: block;
        color: ${colors.black3};
        transition: opacity 0.35s ease-in-out;
        text-transform: none;

        :hover {
          opacity: 1;
          color: ${colors.black3};
          text-decoration: none;
        }
      }

      svg {
        margin: 0;
      }

      .subnav {
        position: absolute;
        flex-flow: column wrap;
        top: -9999em;
        left: -24px;
        background: ${colors.white};
        padding: 24px 40px;
        border-radius: 4px;
        border: 1px solid rgba(20, 20, 20, 0.12);
        min-width: 100px;

        li {
          margin: 0;
          padding: 0;
          line-height: 1.1;
          display: block;

          &:not(:first-child) {
            margin-top: 16px;
          }

          a {
            margin: 0;
            padding: 0;
            display: block;

            &:hover {
              color: ${colors.ramotionBlueHover};
            }
          }
        }
      }
      .cols {
        position: absolute;
        display: flex;
        flex-flow: row wrap;
        top: -9999em;
        left: -200px;
        background: ${colors.white};
        padding: 24px 40px;
        border-radius: 4px;
        border: 1px solid rgba(20, 20, 20, 0.12);
        width: 700px;

        .col {
          display: flex;
          width: 50%;

          strong {
            font-size: 24px;
          }

          ul {
            display: flex;
            flex-flow: row wrap;
            margin: 12px 24px 0;

            li {
              width: 100%;
            }
          }
        }

        li {
          margin: 0;
          padding: 0;
          line-height: 1.1;
          display: block;

          &:not(:first-child) {
            margin-top: 16px;
          }

          a {
            margin: 0;
            padding: 0;
            display: block;

            &:hover {
              color: ${colors.ramotionBlueHover};
            }
          }
        }
      }
    }
  }
`;

export default function SiteNav({ siteMenu }) {
  return (
    <SiteNavWrapper>
      <SiteNavLeft>
        <ul>
          {siteMenu.map(item => (
            <li key={item.id}>
              {item.categoryLink ? (
                <a href={item.categoryLink}>{item.categoryTitle}</a>
              ) : (
                <span>{item.categoryTitle}</span>
              )}

              {item.navItem.length > 0 || item.navColumn.length > 0 ? (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 12L4 7L12 7L8 12Z"
                    fill="#292728"
                  />
                </svg>
              ) : null}

              {item.navItem.length > 0 ? (
                <ul className="subnav">
                  {item.navItem.map(item => (
                    <li key={item.id}>
                      <a
                        href={
                          item.postLink ? item.postLink.slug : item.externalLink
                        }
                      >
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : null}

              {item.navColumn.length > 0 ? (
                <div className="cols">

                  {item.navColumn.map(col => (
                    <div className="col" key={col.id}>
                      <strong>{col.title}</strong>

                        {col.columnItem.length > 0 ? (
                          <ul>
                            {col.columnItem.map(item => (
                              <li key={item.id}>
                                <a
                                  href={
                                    item.postLink ? item.postLink.slug : item.externalLink
                                  }
                                >
                                  {item.title}
                                </a>
                              </li>
                            ))}
                          </ul>
                        ) : null}

                    </div>
                  ))}
                </div>
              ) : null}
            </li>
          ))}
        </ul>
      </SiteNavLeft>
    </SiteNavWrapper>
  );
}
