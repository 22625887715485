import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import { colors } from '../consts/style';
import SiteNav from './SiteNav';
import ContactCta from './ContactCta';
import SiteNavMobile from './SiteNavMobile';
import BurgerMenu from './BurgerMenu';
import HeaderLogo from './HeaderLogo';

const Wrapper = styled.header`
  position: relative;
  background: ${colors.white};
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  z-index: 1000;
  margin: 0 auto;
  padding: 17px 16px;
  width: 100%;
  max-width: 1344px;
  /* height: 72px; */

  @media (max-width: 1200px) {
    padding: 12px 15px;
  }

  &:before {
    content: '';
    background: ${colors.white};
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
  }
`;

export default function Header() {
  const data = useStaticQuery(headerQuery);
  const siteMenu = data.nav.nodes;
  const headerLogo = data.config.headerLogoSvgNode.childMarkdownRemark.html;
  const headerLinks = data.config.headerLink;
  const [navMobileIsOpen, toggleNavMobile] = useState(false);

  const toggleNav = () => {
    toggleNavMobile(!navMobileIsOpen);
    document.querySelector('body').classList.toggle('mobile-nav-visible');
  };

  const closeNav = () => {
    toggleNavMobile(false);
    document.querySelector('body').classList.remove('mobile-nav-visible');
  };

  return (
    <Wrapper>
      <BurgerMenu isOpen={navMobileIsOpen} toggle={toggleNav} />
      <HeaderLogo closeNav={closeNav} headerLogo={headerLogo} />
      <SiteNav siteMenu={siteMenu} />
      <ContactCta />
      
      <SiteNavMobile
        isOpen={navMobileIsOpen}
        siteMenu={siteMenu}
        toggle={toggleNav}
      />
    </Wrapper>
  );
}

const headerQuery = graphql`
  {
    nav: allDatoCmsHeaderNav(sort: { fields: position }) {
      nodes {
        id
        categoryTitle
        categoryLink
        navItem {
          id
          title
          externalLink
          postLink {
            slug
          }
        }
        navColumn {
            id
            title
            columnItem {
                id
                title
                postLink {
                    slug
                }
                externalLink
            }
          }
      }
    }
    config: datoCmsSiteConfig {
      headerLogoSvgNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
