import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { colors } from '../consts/style';

const Wrapper = styled.div`
  width: 100%;
  background: ${colors.white};
  position: fixed;
  top: 72px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 0 50px;
  transform: translateY(-150%);
  transition: transform 0.4s;
  z-index: 1;
  overflow-y: auto;

  @media (max-width: 1200px) {
    transform: ${props =>
      props.isOpen ? 'translateY(0)' : 'translateY(-150%)'};
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      text-align: left;
      display: flex;
      align-items: center;
      flex-flow: row wrap;

      a,
      span {
        color: ${colors.black3};
        display: block;
        font-weight: 300;
        font-size: 16px;
        line-height: 50px;
        text-align: left;
        padding: 0 20px;
        cursor: pointer;

        &:hover {
          background: ${colors.gray8};
          text-decoration: none;
        }
      }

      .cols {
        padding: 0 16px;
      }
    }
  }
`;

const SubNav = styled.ul`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  border-bottom: 1px solid #ddd;
  width: 100%;
`;

export default function SiteNavMobile({ isOpen, siteMenu, toggle }) {
  const [subNavIsOpen, setsubNavIsOpen] = useState(false);

  const toggleSubNav = () => {
    setsubNavIsOpen(!subNavIsOpen);
  };

  return (
    <Wrapper isOpen={isOpen}>
      <ul>
        {siteMenu.map(item => (
          <li key={item.id} onClick={toggleSubNav}>
            {item.categoryLink ? (
              <a href={item.categoryLink}>{item.categoryTitle}</a>
            ) : (
              <span>{item.categoryTitle}</span>
            )}

            {item.navItem.length > 0 || item.navColumn.length > 0 ? (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 12L4 7L12 7L8 12Z"
                  fill="#292728"
                />
              </svg>
            ) : null}

            {item.navItem.length > 0 ? (
              <SubNav isOpen={subNavIsOpen}>
                {item.navItem.map(item => (
                  <li key={item.id}>
                    <a
                      href={
                        item.postLink ? item.postLink.slug : item.externalLink
                      }
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
              </SubNav>
            ) : null}

            {item.navColumn.length > 0 ? (
              <SubNav isOpen={subNavIsOpen}>
                <div className="cols">

                  {item.navColumn.map(col => (
                    <div className="col" key={col.id}>
                      <strong>{col.title}</strong>

                        {col.columnItem.length > 0 ? (
                          <ul>
                            {col.columnItem.map(item => (
                              <li key={item.id}>
                                <a
                                  href={
                                    item.postLink ? item.postLink.slug : item.externalLink
                                  }
                                >
                                  {item.title}
                                </a>
                              </li>
                            ))}
                          </ul>
                        ) : null}

                    </div>
                  ))}
                </div>
              </SubNav>
            ) : null}
          </li>
        ))}
      </ul>
    </Wrapper>
  );
}
