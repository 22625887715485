// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-authors-js": () => import("./../../../src/templates/Authors.js" /* webpackChunkName: "component---src-templates-authors-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/BlogList.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/Tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

